<template>
    <div class="container flex flex-col">

      <div class="flex flex-row items-center practice-invite-container" v-if="!loading">
          <div class="flex-grow p-4">
              <h1 class="text-black text-xl lg:text-2xl pb-4 pb-12" data-cy="practiceName">You've been invited to add a
                  practice!</h1>
              <p class="pb-12">
                  <span class="font-bold">{{ inviterName }}</span> has invited you to add <span class="font-bold">{{ practiceName }}</span>
                  to your Connect account.
              </p>

                <div class="">
                    <el-button
                        class="uppercase font-display"
                        data-cy="addPracticeLink"
                        @click.prevent="declineInvitation"
                        :loading="rejecting"
                        :disabled="accepting"
                    >
                        Do not add practice
                    </el-button>
                    <el-button
                        class="uppercase font-display"
                        data-cy="addPracticeLink"
                        type="success"
                        @click.prevent="acceptInvitation"
                        :loading="accepting"
                        :disabled="rejecting"
                    >
                        Yes, add practice to my account
                    </el-button>
                </div>
            </div>
            <div class="flex w-2/5 p-4">
                <img src="@/assets/images/image_welcomelanding.png" alt="Welcome to Connect" class="block"/>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>

    </div>
</template>

<script>
import GlobalHeader from '@/components/shared/GlobalHeader'
import GlobalFooter from '@/components/shared/GlobalFooter'
import camelcaseKeys from 'camelcase-keys'
import Loader from '@/assets/loader.svg'
import MessageDialog from '@/components/shared/mixins/messageDialog'

export default {
    name: "PracticeInvite",
    mixins: [MessageDialog],
    components: {GlobalHeader, GlobalFooter, Loader},
    props: {
        invitationToken: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            invitation: null,

            loading: false,
            accepting: false,
            rejecting: false
        }
    },
    mounted() {
        this.loadInvitation()
    },
    methods: {
        loadInvitation() {
            this.loading = true

            this.$http({
                method: 'GET',
                url: `web.accountinvitation/${this.invitationToken}/`
            }).then(res => {
                this.invitation = res.data.invitation ? camelcaseKeys(res.data.invitation, {deep: true}) : null
            }).catch(err => {
                this.goToDefaultPageAfterLogin()
            }).finally(() => {
                this.loading = false
            })
        },
        acceptInvitation() {
            console.log('accepting invitation')

            this.accepting = true
            this.$http({
                method: 'POST',
                url: `web.accountinvitation/${this.invitationToken}/accept/`
            }).then(({ data }) => {
                this.message = `You have accepted the invite to <strong>${this.practiceName}</strong>.`
                this.type = 'success'
                this.showMessage({ duration: 5000, dangerouslyUseHTMLString: true })

                return this.$auth.refresh().catch(() => {
                    console.log('failed in router index.js')
                })
            }).then(() => {
                this.$router.push({
                    name: 'Staff',
                    params: {id: this.invitation.staff.practice.id + ""}
                })
            }).catch(err => {
                this.message = 'An error occurred while accepting the invitation.'
                this.type = 'error'
                this.showMessage({duration: 5000})
                console.error(err)
            }).finally(() => {
                this.accepting = false
            })
        },
        declineInvitation() {
            console.log('declining invitation')

            this.rejecting = true

            this.$http({
                method: 'POST',
                url: `web.accountinvitation/${this.invitationToken}/decline/`
            }).then(res => {
                this.message = `You have rejected the invite to <strong>${this.practiceName}</strong>.`
                this.type = 'success'
                this.showMessage({duration: 5000, dangerouslyUseHTMLString: true})

                this.goToDefaultPageAfterLogin()
            }).catch(err => {
                this.message = `An error occurred while declining the invitation.`
                this.type = 'error'
                this.showMessage({duration: 5000, dangerouslyUseHTMLString: true})
                console.error(err)
            }).finally(() => {
                this.rejecting = false
            })
        },
        async goToDefaultPageAfterLogin() {
            if (this.$auth.user()['singlePractice']) {
                if (this.$auth.user()['paymentPending']) {
                    const httpOptions = this.getPostOrPatchOptions({}, `web.practice/${this.$auth.user().practiceId}`, true)

                    try {
                        const response = await this.$http(httpOptions)
                        const practice = camelcaseKeys(response.data, {deep: true})
                        this.goToBackendUrl(practice.applicationPaymentUrl)
                    } catch (e) {
                        // TODO: do something if the get doesn't work
                    }
                } else {
                    this.$router.push({
                        name: 'Staff',
                        params: {id: this.$auth.user().practiceId}
                    })
                }
            } else {
                this.$router.push({name: 'PracticeList'})
            }
        }
    },
    computed: {
        inviterName() {
            return this.invitation ? `${this.invitation.inviter.firstName} ${this.invitation.inviter.lastName}` : null
        },
        practiceName() {
            return this.invitation ? this.invitation.staff.practice.name : null
        }
    },
}
</script>
